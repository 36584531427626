/* This file is for your main application css. */

@import "./phoenix.css";

table.xxapplet tr {
}

table.xxapplet tr.odd {
    background-color: #eee;
}

table.xxapplet td {
    padding: 2px 8px;
    line-height: 18px;
    width: 50px;
}    

th.xxappletth {
    padding: 2px 8px;
    color: #fff;
    font-weight: bold;
    background-color: #002573;
}

.xxxapplet {
    height: 24px;
    padding: 0px 5px 0px 5px;
}

/**********/
/** MODS **/
/**********/

table.applet {
    font-family: 'Open Sans', 'PTSansRegular', Arial, Helvetica, sans-serif;
    font-size: 11px;
    width: 354px;
    border-spacing: 1px;
}

th.center, td.center {
    text-align: center;
}

th.right, td.right {
    text-align: right;
}

th.w100 {
    width: 100px;
}
.seconds {
    color:#31ce63;
}
  
/************************/
/***** applet/table *****/
/************************/
.applet {
    margin:0px;
    padding:0px;
    border:1px solid #eee;
  }
  .applet .col-header.one {
      padding:2px 8px;
  }
  .applet .col-header.two {
      padding:2px 4px;
  }
  .applet .col-header {
    color:#fff;
    font-size:11px;
    font-weight:bold;
    background-color:#002573;
    white-space:nowrap;
  }
  .applet .row-odd {
    height:24px;
    padding:0px 5px 0px 5px;
    font-size:11px;
    background-color:#fff;
    border: none;
}
.applet .row-even {
    height:24px;
    padding:0px 5px 0px 5px;
    font-size:11px;
    background-color:#eee;
    border: none;
}
  .applet .row-applet {
    padding:0px;
    background-color:#fff;
  }
  .applet .txt-plus {
    color:#0c0;
  }
  .applet .txt-minus {
    color:#f00;
  }
  
  .table {
    margin:0px;
    padding:0px;
    border:1px solid #eee;
  }
  .table .col-header {
    padding:2px 8px;
    color:#fff;
    font-size:11px;
    font-weight:bold;
    background-color:#002573;
    white-space:nowrap;
  }
  .table .row-odd {
    padding:5px;
    font-size:11px;
    background-color:#fff;
  }
  .table .row-even {
    padding:5px;
    font-size:11px;
    background-color:#eee;
  }
  .table .row-divider {
    padding:0px;
    background-color:#002573;
    height:1px;
  }

/* Items for new site */

.navigation_list, nav ul.navbar-nav {
  border-bottom: solid 1px #ccc;
  padding: 20px 10px;
  text-align: center;
}
.navigation_item, nav li.nav-item {
  display: inline;
  list-style: none;
  margin: 0 20px;
}
span.nav-item {
  padding: 20px 10px;
  text-align: center;
}


/* D3 */
.homepage_chart div {
  font: 10px sans-serif;
  background-color: steelblue;
  text-align: right;
  padding: 3px;
  margin: 1px;
  color: white;
}  
  